export const seo = {
  url: 'transport-miedzynarodowy',
  title: {
    pl: 'Transport Międzynarodowy | Transport w Krajach | Transport Europa',
    en: 'International Transport | Transport in Countries | Transport Europe',
  },
  desc: {
    pl: '✓Transport Międzynarodowy FTL ✓Transport Międzynarodowy LTL ✓Realizujemy transport na cały świat ✓Szybka wycena transportu ✓Skontaktuj się z nami.',
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'europa',
    'transport międzynarodowy',
  ],
}

export const intro = {
  title: {
    pl: 'Transport Międzynarodowy',
    en: 'Transport Międzynarodowy',
  },
  desc: {
    pl: 'Realizujemy transport krajowy i międzynarodowy do wszystkich krajów w Europie. Organizujemy transport w modelu FTL i LTL. Rozwiń z nami swój biznes.',
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Transport Międzynarodowy - wybierz doświadczonego partnera logistycznego',
        en: 'International Transport - choose an experienced logistics partner',
      },
      texts: [
        {
          pl: '<span><strong>Transport międzynarodowy</strong> obejmuje zagadnienia związane ze spedycją oraz procesami logistycznymi rozwiniętymi w szerszym zakresie niż transport krajowy. Ten typ cechuje bardzo <strong>sprawna sieć dostarczania ładunków</strong> z miejsca podjęcia do miejsca docelowego. Skupiamy się na dobraniu odpowiedniej usługi transportowej, organizacji <strong>bezpiecznej trasy</strong> transportu oraz <strong>punktualności dostaw</strong>.</span>',
          en: '<span><strong>International transport</strong> covers issues related to forwarding and logistic processes developed to a greater extent than domestic transport. This type is characterized by a very <strong>efficient cargo delivery network</strong> from pick-up to destination. We focus on choosing the right transport service, organizing <strong>safe route</strong> transport and <strong>punctual deliveries</strong>.</span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Transport Intermodalny',
        en: 'Intermodal Transport',
      },
      texts: [
        {
          pl: '<span>Od 2020 roku realizujemy naszym klientom <strong>ekologiczny transport intermodalny</strong>, który umożliwia obniżenie kosztów przejazdu na określonych trasach i kierunkach. Jest to transport, w którym wykorzystywane są <strong>dwie form przewozu w jednej jednostce</strong> na całej trasie. W transporcie intermodalnym istnieje możliwość przygotowania <strong>długoterminowej umowy z gwarancją cenową</strong>. To rozwiązanie dedykowane dla firm chcących uzyskać gwarancję stabilności kosztów transportu przez cały rok. Bez wątpienia zaletami transportu intermodalnego jest <strong>bezpieczeństwo ładunku</strong> i <strong>optymalizacja czasu przewozu</strong>.</span>',
          en: '<span> From 2020, we offer our clients <strong> ecological intermodal transport </strong>, which allows you to reduce the cost of travel on specific routes and directions. It is a transport in which we use <strong> several forms of transport in one unit </strong> along the entire route. In intermodal transport, it is possible to prepare <strong> a long-term contract with a price guarantee </strong>. This solution is dedicated to companies wishing to obtain a guarantee of stability of transport costs throughout the year. Undoubtedly, the advantages of intermodal transport are <strong> cargo safety </strong> and <strong> optimization of transport times </strong>. </span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Jakie typy transportu międzynarodowego realizujemy?',
        en: 'What types of international transport we offer?',
      },
      texts: [
        {
          pl: `
          <div>
          <p>Omida VLS to firma transportowa posiadająca szeroką gamę produktów i usług transportowych, dedykowana jest poszczególnym gałęziom gospodarki. Realizujemy różne formy transportu takie jak:</p>
            <ul>
              <li><a href="/transport-calopojazdowy/">Transport Całopojazdowy</a></li>
              <li><a href="/transport-drobnicowy/">Transport Drobnicowy</a></li>
              <li><a href="/transport-intermodalny/">Transport Intermodalny</a> w ramach kierunku - Niemcy, Belgia, Holandia</li>
            </ul>
          </div>
          `,
          en: `
          <div>
           <p> Omida VLS is a transport company with a wide range of transport products and services, dedicated to individual branches of the economy. We offer various forms of transport, such as: </p>
             <ul>
               <li><a href="/transport-calopojazdowy/">FTL Transport</a></li>
               <li><a href="/transport-drobnicowy/">LTL Transport</a></li>
               <li><a href="/transport-intermodalny/">Intermodal Transport</a> as part of the direction - Germany, Belgium, the Netherlands</li>
             </ul>
           </div>
          `,
        },
        {
          pl: `
          <div>
          Wszystkie typy transportu, jakie realizujemy na szczeblu krajowym oraz międzynarodowym, znajdują się w <a href="/transport-rodzaje/">zakładce Usługi Transportowe</a>. Zachęcamy do wyboru odpowiedniej usługi transportowej, dobranej do <strong>indywidualnych preferencji</strong>. Aby dowiedzieć się więcej na temat organizacji i procesów związanych z planowaniem przewozu do innego kraju - <a href="/wycena-transportu/">wyceń swój transport</a>.
          </div>
          `,
          en: `
          <div>
           All types of transport we offer nationally and internationally can be found in the <a href="/transport-rodzaje/"> Transportation Services tab </a>. We encourage you to choose the appropriate transport service, matched to <strong> individual preferences </strong>. To find out more about the organization and processes involved in planning a trip to another country - <a href="/wycena-transportu/"> quote your transport </a>.
           </div>
          `,
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Powrót do transportu',
    en: 'Back to transport',
  },
  link: '/transport/',
}
